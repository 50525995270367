import React from "react";
import { Typography, Box } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import Page from "./Page";
import gsap from "gsap";
import { ScrollTrigger, ScrollSmoother } from "gsap/all";
import { scrollDownIndicatorRef } from "../../App";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

function Team() {
  const theme = useTheme();
  const containerRef = React.useRef();
  const containerRefAJ = React.useRef();
  const triggerRefAJ = React.useRef();
  const containerRefSteven = React.useRef();
  const triggerRefSteven = React.useRef();
  // const pageScrollerRef = React.useRef();
  // const scrollDownIndicatorInitialRevealDelay = 0.8;
  ScrollTrigger.killAll();

  const triggerRefs = React.useRef([
    triggerRefSteven,
    triggerRefAJ,
  ]);

  // custom styled components
  const TeamMemberContainer = styled(Box)(({ theme }) => ({
    maxWidth: "760px",
    margin: "10px",
    marginBottom: "50px",
  }));
  const TeamMemberName = styled(Typography)(({ theme }) => ({
    fontFamily: "Normal Bold",
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: "35.2px",
    color: "rgb(9,17,46)",
    marginTop: "20px",
    marginBottom: "10px",
    textTransform: "uppercase",
    textAlign: "center",
  }));
  const TeamMemberTitle = styled(Typography)(({ theme }) => ({
    fontFamily: "Normal Light",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "17.6px",
    color: "rgb(9,17,46)",
    marginBottom: "26px",
    textTransform: "uppercase",
    textAlign: "center",
  }));
  const TeamMemberBio = styled(Typography)(({ theme }) => ({
    fontFamily: "Normal Bold",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "20px",
    color: "rgb(77,77,77)",
    marginBottom: "10px",
    textAlign: "left",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
    },
  }));

  // setup team member section scroll triggers
  React.useLayoutEffect(() => {
    const teamMemberContainerRefs = [
      containerRefSteven,
      containerRefAJ,
    ];

    teamMemberContainerRefs.forEach((teamMemberRef, i) => {
      const name = teamMemberRef.current.querySelector("h2");
      const title = teamMemberRef.current.querySelector("h3");
      const bio = teamMemberRef.current.querySelector("p");
      const memberSections = [name, title, bio];
      memberSections.forEach((section) => {
        gsap.set(section, { autoAlpha: 0, y: 10 });
        const tl = gsap.timeline();
        tl.to(section, {
          y: 0,
          autoAlpha: 1,
          delay: 0.2,
        });
        triggerRefs.current[i].current = new ScrollTrigger({
          animation: tl,
          trigger: section,
          start: "top 80%",
          end: "top 60%",
          // toggleActions: "play none none reverse",
          invalidateOnRefresh: true,
        });
      });
    });

    return () => {
      // eslint-disable-next-line
      triggerRefs.current.forEach((ref) => {
        ref.current?.kill();
      });
    };
  }, []);

   // setup scroll down indicator
  React.useEffect(() => {
    // When more team members are added, then comment out this next line
    // Hide the scroll down indicator initially
    scrollDownIndicatorRef.current?.instantHide();
    // When more team members are added, then uncomment the remainder
    // let scrollTimeout;

    // gsap.delayedCall(0.1, () => {
    //   pageScrollerRef.current = ScrollTrigger.create({
    //     trigger: containerRef.current,
    //     start: "top top",
    //     end: "bottom bottom",
    //     onUpdate: (self) => {
    //       if (self.direction === 1 && !self.isActive) {
    //         // Scrolling down and reached the bottom
    //         scrollDownIndicatorRef.current.hide();
    //       } else if (self.direction === -1 && self.progress === 0) {
    //         // Scrolling up and reached the top
    //         scrollDownIndicatorRef.current.show();
    //       } else {
    //         // Scrolling in progress
    //         scrollDownIndicatorRef.current.hide();
    //         clearTimeout(scrollTimeout);
    //         scrollTimeout = setTimeout(() => {
    //           if (self.progress !== 1) {
    //             // Not at the bottom, show the indicator
    //             scrollDownIndicatorRef.current.show();
    //           }
    //         }, 300); // Adjust the delay as needed
    //       }
    //     },
    //   });
    // });

    // gsap.delayedCall(scrollDownIndicatorInitialRevealDelay, () => {
    //   scrollDownIndicatorRef.current.colorSchemeBlack();
    //   scrollDownIndicatorRef.current.show();
    // });

    // return () => {
    //   pageScrollerRef.current.kill();
    //   scrollDownIndicatorRef.current.instantHide();
    //   clearTimeout(scrollTimeout);
    // };
  }, []);

  return (
    <Page>
      <Box
        ref={containerRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
          color: "#000",
          minHeight: "100vh", // Ensures white background covers full screen
          padding: "30px 2em 40px",
          [theme.breakpoints.up("md")]: {
            padding: "90px 2em 50px",
          },
        }}
      >
        <TeamMemberContainer ref={containerRefAJ}>
          <TeamMemberName variant="h2">AJ MCDERMOTT</TeamMemberName>
          <TeamMemberTitle variant="h3">
            PRESIDENT & CO-FOUNDER
          </TeamMemberTitle>
          <TeamMemberBio>
            AJ is a 20 year global security and intelligence expert who
            architects advanced technology focusing on remote sensing, data
            aggregation and data visualization solutions. He recommends and
            develops mission oriented best practices and advises governments and
            private sector clients on matters of national security and
            infrastructure protection. Mr. McDermott has traveled & worked in
            over 60 countries, advising senior government leadership on topics
            of defense, national security, integrated health care and
            catastrophic consequence analysis. AJ is a software architect and
            user interface subject matter expert that has architected command &
            control systems for the Pentagon’s Global Situational Awareness
            Facility, FEMA’s National Coordination Center, and various
            Intelligence solutions for Federal, State and local Governments.
          </TeamMemberBio>
        </TeamMemberContainer>

        <TeamMemberContainer ref={containerRefSteven}>
          <TeamMemberName variant="h2">STEVEN WOOLSEY</TeamMemberName>
          <TeamMemberTitle variant="h3">
            CTO & CO-FOUNDER
          </TeamMemberTitle>
          <TeamMemberBio>
            Steven is a software engineer with over 27 years of development and
            software architectural experience. He has worked in both commercial
            and government sectors. He was the lead software developer of
            critical infrastructure interdependency modeling and simulation
            software nominated by the Idaho National Laboratory for a
            2007 Department of Energy R&D 100 Award and winner of the
            2007 Idaho Innovations Award. At Priority 5, he designed
            software used for monitoring security at presidential inaugurations,
            used by FEMA to manage hurricane relief, and used by DOD.  At the
            Space Dynamics Laboratory, he was product lead in charge of the real-time
            processing subsystems of ground stations for high altitude sensors.
            Steve's areas of expertise include high performance computing,
            3D visualization, cybersecurity, scientific modeling and simulation,
            user experience, plugin subsystems.
          </TeamMemberBio>
        </TeamMemberContainer>

      </Box>
    </Page>
  );
}

export default Team;
