import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Typography,
  useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import styled from '@emotion/styled';
import { AppContext } from "../App";
import HomePageSections from "./Pages/Home-Page-Sections/HomePageSections";

const CopyrightNotice = () => {
  const theme = useTheme();
  const location = useLocation();
  const { state } = React.useContext(AppContext);
  const isUserManagementPage = location.pathname === '/user-management';
  const isLoginPage = location.pathname === '/';
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const onHomePage = location.pathname.includes('/home');

  const StyledBox = styled(Box)(({ backgroundColor }) => ({
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: backgroundColor,
    padding: isSmallScreen ? "8px 0" : "4px 0",
    zIndex: 80,
  }));

  const StyledTypography = styled(Typography)({
    color: '#4d84e1',
    fontSize: isSmallScreen ? '8px' : isMediumScreen ? '9px' : '12px',
    fontFamily: 'Normal Light',
    letterSpacing: '3px',
    // height: '20px',
    textAlign: 'center',
    userSelect: 'none',  // Add this line to make the text non-selectable
  });

  const getBackgroundColor = () => {
    if (onHomePage) {
      const currentSection = HomePageSections.find(section => location.pathname.includes(section.path));
      return currentSection && currentSection.coloredNavbar ? currentSection.coloredNavbar : "transparent";
    }
    return "#333333AA";
  };
  const backgroundColor = getBackgroundColor();

  return (
    <>
      {!isUserManagementPage && !isLoginPage && state.isLoggedIn && (
        <StyledBox backgroundColor={backgroundColor}>
          <StyledTypography>
            <span>Confidential and Proprietary. </span>
            {isSmallScreen && (<br/>)}
            <span>Copyright © by Priority 6, Inc. All Rights Reserved.</span>
          </StyledTypography>
        </StyledBox >
      )}
    </>
  );
};

export default CopyrightNotice;
