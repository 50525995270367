import * as React from 'react';
import { useLocation } from 'react-router-dom';
import {
  AppBar,
  Box,
  Toolbar,
  useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Spin as Hamburger } from 'hamburger-react'

import { AppContext } from '../App';
import HomePageNavbar from './HomePageNavbar';
import HomePageSections from './Pages/Home-Page-Sections/HomePageSections';
import Menu from './Menu';
import NavbarTitle from './NavbarTitle';

const LogoText = styled(Typography)(({ theme, small, medium }) => ({
  position: 'fixed',
  color: '#4d84e1',
  backgroundColor: 'rgba(0,0,0,0.0)',
  backgroundImage: 'none',
  fontFamily: 'Dioropen',
  fontSize: small ? '14px' : medium ? '18px' : '22px',
  WebkitTextRendering: 'optimizeSpeed',
  textRendering: 'optimizeSpeed',
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)',
  marginRight: '10px',
}));

const StyledToolbar = styled(Toolbar)(({ theme, small, medium }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
  padding: small ? '0 10px' : medium ? '0 15px' : '0 20px',
  minHeight: 'unset',
}));

function Navbar() {
  const theme = useTheme();
  const [isOpen, setOpen] = React.useState(false)
  const [onHomePage, setOnHomePage] = React.useState();
  const location = useLocation();
  const { state, handleLogout } = React.useContext(AppContext);
  const isUserManagementPage = location.pathname === '/user-management';

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const currentSection = HomePageSections.find(section => location.pathname.includes(section.path));

  React.useEffect(() => {
    setOnHomePage(location.pathname.includes('/home'));
  }, [location]);

  // Determine the navbar color based on the route
  const getNavbarColor = () => {
    if (!isOpen && onHomePage) {
      const currentSection = HomePageSections.find(section => location.pathname.includes(section.path));
      return currentSection && currentSection.coloredNavbar ? currentSection.coloredNavbar : 'transparent';
    }
    return '#333333AA';
  };

  // Determine the invertNavbar value based on the current section
  const invertNavbar = currentSection && currentSection.invertNavbar;
  const navbarColor = getNavbarColor();

  return (
    <Box
      sx={{ flexGrow: 1, visibility: state.isLoggedIn ? 'visible' : 'hidden' }}
    >
      {state.isLoggedIn && (
        <>
          <AppBar
            position='fixed'
            sx={{
              backgroundColor: navbarColor,
              color: invertNavbar && !isOpen ? 'darkblue' : 'white',
              backgroundImage: 'none',
              boxShadow: 'none',
              height: isSmallScreen ? '40px' : isMediumScreen ? '52px' : '64px',
            }}
          >
            <StyledToolbar small={isSmallScreen} medium={isMediumScreen}>
              <LogoText small={isSmallScreen} medium={isMediumScreen}>
                6
              </LogoText>
              {!isUserManagementPage && (
                <Box
                  sx={{
                    width: '100%',
                    overflow: 'visible',
                    [theme.breakpoints.down('sm')]: {
                      overflow: 'hidden',
                    },
                    visibility: isOpen ? 'hidden' : 'visible',
                  }}
                >
                  {onHomePage && <HomePageNavbar invertNavbar={invertNavbar} isMenuOpen={isOpen} />}
                  {!onHomePage && <NavbarTitle />}
                </Box>
              )}
              <Hamburger
                size={isSmallScreen ? 10 : isMediumScreen ? 15 : 20}
                toggled={isOpen}
                toggle={setOpen}
              />
            </StyledToolbar>
          </AppBar>
          <Menu menuOpen={isOpen} toggleMenuOpen={setOpen} handleLogout={handleLogout} />
        </>
      )}
    </Box>
  );
}

export default Navbar;
