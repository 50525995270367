import React, { useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import gsap from "gsap";
import { SplitText, ScrollTrigger } from "gsap/all";
import nestedLinesSplit from "../Utilities/nestedLinesSplit";

gsap.registerPlugin(SplitText, ScrollTrigger);

const StyledStarWarsText = styled(Typography)(({ theme, isSmallScreen }) => ({
  color: "#fff",
  fontFamily: "Normal Light",
  fontKerning: "none",
  WebkitTextRendering: "optimizeSpeed",
  textRendering: "optimizeSpeed",
  WebkitTransform: "translateZ(0)",
  transform: "translateZ(0)",
  fontSize: isSmallScreen ? '16px' : '22px',
  visibility: "hidden",
  width: "100%",
  textAlign: "center",
  textShadow: '8px 8px 16px rgba(0, 0, 0, 1)',
  whiteSpace: 'pre-wrap',
  userSelect: 'none',
  WebkitBackfaceVisibility: 'hidden',
  backfaceVisibility: 'hidden',
  [theme.breakpoints.up("md")]: {
    fontSize: isSmallScreen ? '24px' : '32px',
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: isSmallScreen ? '32px' : '48px',
  },
  [theme.breakpoints.down('md')]: {
    '& > span:last-child': {
      display: 'block',
      marginTop: isSmallScreen ? '100px' : '0', // Adjust the value as needed
      fontSize: isSmallScreen ? '48px' : '70px',
    },
  },
  "& .star-wars-text-line": {
    visibility: "hidden",
    justifyContent: "center",
    marginBottom: "10px",
    lineHeight: "31px",
    paddingBottom: "10px",
    [theme.breakpoints.up("md")]: {
      lineHeight: "68px",
      paddingBottom: 0,
    },
    "&.imgleft": {
      justifyContent: "flex-start",
    },
    "&.imgright": {
      justifyContent: "flex-end",
    },
  },
  "& .image-container": {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    "&.left": {
      justifyContent: "flex-start",
    },
    "&.right": {
      justifyContent: "flex-end",
    },
  },
  "& .background-final-line .star-wars-text-line": {
    fontFamily: "Normal Bold",
    [theme.breakpoints.up("lg")]: {
      fontSize: "52px",
    },
  },
  '& .quote': {
    fontSize: isSmallScreen ? '22px' : '48px',
    // fontWeight:'bold',
  },
  '& .author': {
    fontSize: isSmallScreen ? '16px' : '22px',
    fontStyle:'italic',
    // textAlign: 'right',
    marginRight: theme.spacing(36), // Or remove this line if you don't want any right margin
    display: 'block', // Add this line
    width: 'fit-content', // Add this line
    marginLeft: 'auto', // Add this line
  },
  '& .left': {
    display: 'block', // Add this line
    textAlign: 'left',
  },
  '& .right': {
    display: 'block', // Add this line
    textAlign: 'right',
  },
}));

function StarWarsText({ children, isSmallScreen, specialLastLine }) {
  const containerRef = React.useRef();
  const textRef = React.useRef();
  const splitTextRef = React.useRef();
  const scrollTriggerTLs = React.useRef([]);
  const scrollTriggers = React.useRef([]);
  const [isMounted, setIsMounted] = React.useState(false);

  const isFirefox = typeof navigator !== "undefined" && /firefox/i.test(navigator.userAgent);

  const textSizeStyles = {
    'text-8': { fontSize: isSmallScreen ? '8px' : '8px' },
    'text-12': { fontSize: isSmallScreen ? '10px' : '10px' },
    'text-16': { fontSize: isSmallScreen ? '10px' : '16px' },
    'text-22': { fontSize: isSmallScreen ? '16px' : '22px' },
    'text-24': { fontSize: isSmallScreen ? '18px' : '24px' },
    'text-28': { fontSize: isSmallScreen ? '20px' : '28px' },
    'text-32': { fontSize: isSmallScreen ? '24px' : '32px' },
    'text-38': { fontSize: isSmallScreen ? '28px' : '38px' },
    'text-44': { fontSize: isSmallScreen ? '36px' : '44px' },
    'text-48': { fontSize: isSmallScreen ? '32px' : '48px' },
    'text-56': { fontSize: isSmallScreen ? '38px' : '56px' },
    'text-64': { fontSize: isSmallScreen ? '48px' : '64px' },
    'text-70': { fontSize: isSmallScreen ? '48px' : '70px' },
    'text-90': { fontSize: isSmallScreen ? '56px' : '90px' },
    'text-120': { fontSize: isSmallScreen ? '90px' : '120px' },
    'text-124': { fontSize: isSmallScreen ? '94px' : '124px' },
    'forever': { fontSize: isSmallScreen ? '42px' : '70px', letterSpacing: '0.19em' },
    // ... other text size styles
  };

  const colorStyles = {
    'color-e8d5b5': { color: '#e8d5b5' },
    'color-00ab7a': { color: '#00ab7a' },
    'color-a2001c': { color: '#a2001c' },
    'color-fdfbcb': { color: '#fdfbcb' },
    'color-e66129': { color: '#e66129' },
    'red': {color: 'red'},
    'blue': {color: 'blue'},
    // ... other color styles
  };

  const fontStyles = {
    'rephen': { fontFamily: 'Rephen' },
    'dioropen': { fontFamily: 'Dioropen' },
  };

  function getStylesFromClassNames(classNames) {
    const styles = {};

    if (typeof classNames === 'string') {
      classNames.split(' ').forEach((className) => {
        if (textSizeStyles[className]) {
          Object.assign(styles, textSizeStyles[className]);
        }
        if (colorStyles[className]) {
          Object.assign(styles, colorStyles[className]);
        }
        if (fontStyles[className]) {
          Object.assign(styles, fontStyles[className]);
        }
        // ... check for other style categories
      });
    }

    return styles;
  }

  function animateRegularLine(line, index, { minScale, maxScale, baseTransformOrigin, baseStartPosition, baseEndPosition, ease }) {
    let initialScale, initialAlpha;
    if (index < 3) {
      initialScale = 0.6;
      initialAlpha = 0.5;
    } else {
      initialScale = minScale;
      initialAlpha = 0;
    }
    const tl = gsap.timeline();
    tl.fromTo(
      line,
      {
        autoAlpha: initialAlpha,
        scale: initialScale,
        transformOrigin: baseTransformOrigin,
      },
      {
        autoAlpha: 1,
        scale: 1,
        transformOrigin: baseTransformOrigin,
        ease: ease,
      },
    );
    tl.to(line, {
      autoAlpha: 0,
      scale: maxScale,
      transformOrigin: baseTransformOrigin,
      ease: ease,
    });
    return {
      timeline: tl,
      startPosition: baseStartPosition,
      endPosition: baseEndPosition,
    };
  }

  const animateSpecialLine = useCallback((line, { ease, lastLineStartPosition, lastLineEndPosition }) => {
    const tl = gsap.timeline();
    tl.fromTo(
      line,
      { autoAlpha: 0, scale: 0.8, transformOrigin: "50% 50%" },
      {
        autoAlpha: 1,
        scale: 1.2,
        transformOrigin: "50% 50%",
        ease: isFirefox ? "power2.inOut" : "power3.inOut",
      },
    );
    tl.to(line, { duration: 2 });
    return {
      timeline: tl,
      startPosition: lastLineStartPosition,
      endPosition: lastLineEndPosition,
    };
  }, [isFirefox]);

  function animateImageLine(image, { maxScale, ease, baseTransformOrigin, baseEndPosition }) {
    if (!image) return null; // If no image is found, return null

    const imageAlign = image.getAttribute("data-align");
    const alignmentStyles = {
      left: { marginLeft: 0, marginRight: "auto" },
      center: { marginLeft: "auto", marginRight: "auto" },
      right: { marginLeft: "auto", marginRight: 0 },
    };

    gsap.set(image, {
      ...alignmentStyles[imageAlign || "center"],
      height: "auto",
      margin: "10px 0",
    });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: image,
        start: "top 60%",
        end: "bottom 10%",
        scrub: true,
        invalidateOnRefresh: true,
      },
    });
    tl.fromTo(
      image,
      { // Initial
        autoAlpha: 0,
        scale: 0.6,
        transformOrigin: baseTransformOrigin,
      },
      {
        // Final
        autoAlpha: 2,
        scale: 1,
        transformOrigin: baseTransformOrigin,
        ease: ease,
      }
    );
    // Fade out the image
    tl.to(
      image,
      {
        autoAlpha: 0, // Fades out the image
        scale: maxScale,
        transformOrigin: baseTransformOrigin,
        ease: ease,
      },
      ">"
    );
    return {
      timeline: tl,
      startPosition: "center 20%",
      endPosition: baseEndPosition,
    };
  }

  const setupStarWarsTextEffect = React.useCallback(() => {
    if (!textRef.current) {
      console.warn("Text ref is not available");
      return;
    }
    try {
      splitTextRef.current = nestedLinesSplit(textRef.current, {
        type: "lines",
        linesClass: "star-wars-text-line",
      });

      // Set initial visibility
      gsap.set(textRef.current, { autoAlpha: 1 });
      gsap.set(splitTextRef.current.lines, { autoAlpha: 0 });

      const animationParams = {
        minScale: 0.4,
        maxScale: 1.4,
        ease: "none",
        baseTransformOrigin: "50% 100%",
        baseStartPosition: "center 70%",
        lastLineStartPosition: "top bottom",
        baseEndPosition: "center 17%",
        lastLineEndPosition: "center center",
      };

      // Handle text elements
      splitTextRef.current.lines.forEach((element, i) => {
        let animation , startPosition, endPosition;
        if (i !== splitTextRef.current.lines.length - 1) {
          animation = animateRegularLine(element, i, { ...animationParams });
        } else {
          // This is the last line
          if (specialLastLine) {
            animation = animateSpecialLine(element, { ...animationParams });
          } else {
            animation = animateRegularLine(element, i, { ...animationParams });
          }
        }
        startPosition = animation.startPosition;
        endPosition = animation.endPosition;

        const trigger = ScrollTrigger.create({
          animation: animation.timeline,
          trigger: element,
          start: startPosition,
          end: endPosition,
          scrub: true,
          invalidateOnRefresh: true,
          id: `Star Wars Text: ${i}`,
          // markers: true,
        });
        scrollTriggerTLs.current.push(animation.timeline);
        scrollTriggers.current.push(trigger);
      });

      // Handle image elements
      const imageElements = textRef.current.querySelectorAll("img");
      imageElements.forEach((imageElement) => {
        const imageAnimation = animateImageLine(imageElement, animationParams);
        if (imageAnimation) {
          scrollTriggerTLs.current.push(imageAnimation);
        }
      });
    } catch (error) {
      console.error("Error in setupStarWarsTextEffect:", error);
    }
  }, [specialLastLine, animateSpecialLine]);

  const resetStarWarsEffect = React.useCallback(() => {
    if (splitTextRef.current) {
      splitTextRef.current.revert();
    }

    scrollTriggerTLs.current.forEach((tl) => {
      if (tl && typeof tl.kill === 'function') {
        tl.kill();
      }
    });

    scrollTriggers.current.forEach((trigger) => {
      if (trigger && typeof trigger.kill === 'function') {
        trigger.kill();
      }
    });

    scrollTriggerTLs.current.length = 0;
    scrollTriggers.current.length = 0;

    setupStarWarsTextEffect();
  }, [setupStarWarsTextEffect]);

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  // Star Wars Text Effect
  React.useLayoutEffect(() => {
    if (isMounted) {
      const setup = async () => {
        await new Promise((resolve) => {
          gsap.delayedCall(0.1, () => {
            setupStarWarsTextEffect();
            resolve();
          });
        });
      };
      setup();
    }
  }, [setupStarWarsTextEffect, isMounted]);

  // Handle Window Resize
  React.useEffect(() => {
    const handleResize = () => {
      gsap.delayedCall(0.1, resetStarWarsEffect);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [resetStarWarsEffect]);

  return (
    <Box
      ref={containerRef}
      sx={{
        position: "relative",
        width: "90vw",
        left: "5vw",
        paddingTop: "calc(50vh - (128px + 1.25em))",
        paddingBottom: "calc(50vh - 64px)",
      }}
    >
      <StyledStarWarsText ref={textRef} sx={{ isSmallScreen }}>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            const { className, ...rest } = child.props;
            const styles = getStylesFromClassNames(className);
            return React.cloneElement(child, {
              style: {
                ...styles,
                ...rest.style,
              },
              ...rest,
            });
          }
          return child;
        })}
      </StyledStarWarsText>
    </Box>
  );
}

export default StarWarsText;
