import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useTheme } from "@mui/system";
import Disclaimer from "./Disclaimer";
import axios from "axios";
import { gsap } from "gsap";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

gsap.registerPlugin(ScrambleTextPlugin);

const serverUrl = process.env.REACT_APP_SERVER_URL ? process.env.REACT_APP_SERVER_URL : 'https://bbo1.rocks/server';

function Login({ handleLogin, handleLogout}) {
  const containerRef = React.useRef();
  const welcomeRef = React.useRef(null);
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState("");
  const [loginError, setLoginError] = React.useState("");
  const [passwordExpired, setPasswordExpired] = React.useState(false);
  const [openChangePasswordDialog, setOpenChangePasswordDialog] = React.useState(false);
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [showDisclaimer, setShowDisclaimer] = React.useState(false);
  const [disclaimerAgreed, setDisclaimerAgreed] = React.useState(false);
  const [serverAvailable, setServerAvailable] = useState(false);
  const [healthCheckCompleted, setHealthCheckCompleted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const theme = useTheme();

  const checkServerHealth = useCallback(async (retries = 5) => {
    // console.log(`Server url: ${serverUrl}`);
    axios.get(`${serverUrl}/api/health`).then((response) => {
      if (response.status === 200) {
        setServerAvailable(true);
      } else {
        setServerAvailable(false);
      }
    }).catch((error) => {
      if (retries > 0) {
        setTimeout(() => checkServerHealth(retries - 1), 2000); // Retry after 2 seconds
      } else {
        setServerAvailable(false);
      }
    }).finally(() => {
      setHealthCheckCompleted(true);
    });
  }, []);

  const runScrambleAnimation = useCallback(() => {
    if (welcomeRef.current) {
      gsap.to(welcomeRef.current, {
        scrambleText: {
          text: "welcome to the future",
          chars: "01",  // Binary numbers for the scramble set
          speed: 0.1,
        },
        duration: 2,
        ease: "power1.inOut",
      });
    }
  }, []);

  useEffect(() => {
    // Run both tasks (animation and server health check) in parallel
    checkServerHealth();     // Start the server health check
    runScrambleAnimation();  // Start the animation

    // Copy the ref value to a variable inside the effect
    const welcomeElement = welcomeRef.current;

    return () => {
      // Use the copied variable in the cleanup function
      gsap.killTweensOf(welcomeElement);
    };
  }, [checkServerHealth, runScrambleAnimation]);

  const handleLoginSubmit = async (event, retries = 3) => {
    event.preventDefault();
    setLoginError("");
    setEmailError(false);
    setPasswordError(false);


    // Validate form fields
    if (!email) {
      setEmailError(true);
      setEmailErrorMessage("Email is required");
      return;
    }
    if (!password) {
      setPasswordError(true);
      setPasswordErrorMessage("Password is required");
      return;
    }

    try {
      if (!serverUrl || serverUrl === '/api') {
        console.log('No server URL set');
        setLoginError("Server unavailable. Please try again later.");
        return;
      }
      // Send login request to the backend API
      const response = await axios.post(`${serverUrl}/api/login`, {
        email,
        password
      }, {timeout: 5000});
      // console.log(`Server response: ${response.code}`);
      if (response.status === 200) {
        setLoginError("");
        const { token, role, name, mustChangePassword, passwordExpired, disclaimerAgreed } = response.data;

        setDisclaimerAgreed(disclaimerAgreed);
        setPasswordExpired(passwordExpired);
        if (passwordExpired) {
          console.log("Password has expired");
          return;
        }

        // Store the token, role, and name in local storage
        localStorage.setItem("authToken", token);
        localStorage.setItem("userRole", role);
        localStorage.setItem("userName", name);

        // Handle successful login
        handleLogin(role);

        if (mustChangePassword) {
          // Prompt the user to change their password
          setOpenChangePasswordDialog(true);
        } else if (role !== 'admin' && !disclaimerAgreed) {
          // Show the disclaimer
          setShowDisclaimer(true);
        } else {
          navigate("/home");
        }
      } else {
        console.error('Unable to log in at this time:', response.status, response.data);
      }
    } catch (error) {
      if (retries > 0) {
        console.log(`Retrying login... (${3 - retries + 1}/3)`);
        setTimeout(() => handleLoginSubmit(event, retries - 1), 2000); // Retry after 2 seconds
      } else {
        if (error.code === 'ECONNREFUSED') {
          setLoginError("Server unavailable. Please try again later.");
          return;
        } else {
          console.error("Login failed:", error);
          setLoginError("Login failed. Please try again.")
        }
      }
    }
  }

  const handleChangePassword = async (retries = 3) => {
    try {
      // Send a request to the backend to update the user's password
      const token = localStorage.getItem("authToken");
      if (token) {
        const response = await axios.post(`${serverUrl}/api/change-password`, {currentPassword, newPassword,}, {
          headers: { Authorization: `Bearer ${token}` },
        });

        // Handle the response from the backend
        if (response.status === 200) {
          setLoginError("");
          // Password changed successfully
          setOpenChangePasswordDialog(false);
          // Show the disclaimer
          setShowDisclaimer(true);
        } else {
          throw new Error("Password change failed");
        }
      } else {
        throw new Error('Token not found in local storage');
        // Handle the case where the token is not found (e.g., redirect to login)
      }
    } catch (error) {
      if (retries > 0) {
        console.log(`Retrying password change... (${3 - retries + 1}/3)`);
        setTimeout(() => handleChangePassword(retries - 1), 2000); // Retry after 2 seconds
      } else {
        setLoginError("Password change failed. Please try again.");
      }
    }
  };

  const handleCloseChangePasswordDialog = () => {
    setOpenChangePasswordDialog(false);
    handleLogout();
  };

  const handleDisclaimerAccept = async (retries = 3) => {
    setLoginError("");  // Clear any previous errors

    try {
      const token = localStorage.getItem("authToken");
      if (token) {
        const response = await axios.post(`${serverUrl}/api/disclaimer-agreement`, null, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          // User accepted the disclaimer, proceed with the application
          setShowDisclaimer(false);
          navigate("/home");
        } else {
          throw new Error("Failed to accept the disclaimer");
        }
      } else {
        throw new Error('Token not found in local storage');
        // Handle the case where the token is not found (e.g., redirect to login)
      }
    } catch (error) {
      if (retries > 0) {
        console.log(`Retrying disclaimer acceptance... (${3 - retries + 1}/3)`);
        setTimeout(() => handleDisclaimerAccept(retries - 1), 2000);  // Retry after 2 seconds
      } else {
        console.error("Disclaimer acceptance failed after 3 retries:", error);
        setLoginError("Failed to accept the disclaimer. Please try again later.");
        navigate("/");
      }
    }
  };

  const handleDisclaimerDecline = async (retries = 3) => {
    setLoginError("");  // Clear any previous errors

    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        throw new Error('Token not found in local storage');
      }
      const response = await axios.post(`${serverUrl}/api/disclaimer-decline`, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        console.log("Disclaimer decline recorded successfully.");
        setShowDisclaimer(false);   // Close the disclaimer dialog
        // Optionally, redirect or handle declined disclaimer logic here
        handleLogout();
        navigate("/");
      } else {
        throw new Error("Failed to decline the disclaimer");
      }
    } catch (error) {
      if (retries > 0) {
        console.log(`Retrying disclaimer decline... (${3 - retries + 1}/3)`);
        setTimeout(() => handleDisclaimerDecline(retries - 1), 2000);  // Retry after 2 seconds
      } else {
        console.error("Disclaimer decline failed after 3 retries:", error);
        setLoginError("Failed to decline the disclaimer. Please try again later.");
      }
    }
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 20000,
        width: "100%",
        display: "flex",
        flexDirection: "column", // ?
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center", // ?
        backgroundColor: theme.palette.colors.darkBlue,
        color: "#fff",
      }}
    >
      {!showDisclaimer && (
        <Box>
          <Typography
            ref={welcomeRef}
            variant="h1"
            sx = {{
              fontSize: { xs: "16pt", sm: "24pt", md: "42pt" },
              fontWeight: "bold",
              fontFamily: "Normal Light",
              color: "#fff",
              marginTop: 5,
              marginBottom: 10,
            }}
          >
            welcome to the future!
          </Typography>
        </Box>
      )}
      {healthCheckCompleted ? (
        serverAvailable ? (
          <>
            {!showDisclaimer && (
              <Box
                sx={{
                  marginTop: "-2em",
                }}
              >
                <form onSubmit={handleLoginSubmit}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      "& div": {
                        marginBottom: "5px",
                      },
                    }}
                  >
                    <div>
                      <TextField
                        id="email-address"
                        name="email"
                        type="email"
                        required
                        label="Email address"
                        autoComplete="off"
                        error={emailError}
                        helperText={emailError ? emailErrorMessage : ""}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setEmailError(false);
                        }}
                        sx={{ width: "20em", fontSize: { xs: "8pt", sm: "12pt", md: "16pt" }, }}
                      />
                    </div>
                    <div>
                      <TextField
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        required
                        label="Password"
                        error={passwordError}
                        helperText={passwordError ? passwordErrorMessage : ""}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setPasswordError(false);
                        }}
                        InputProps={{ // add an IconButton to toggle showPassword state
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        sx={{ width: "20em", fontSize: { xs: "8pt", sm: "12pt", md: "16pt" }, }}
                      />
                    </div>
                    <div>
                      <Typography color="error">{loginError}</Typography>
                    </div>
                    <div>
                      <Button type="submit" variant="outlined" color="secondary">
                        Login
                      </Button>
                    </div>
                  </Box>
                </form>
              </Box>
            )}
            {/* Render the ChangePassword dialog when mustChangePassword is true */}
            <Dialog
              open={openChangePasswordDialog}
              onClose={handleCloseChangePasswordDialog}
            >
              <DialogTitle>Change Password</DialogTitle>
              <DialogContent>
                <TextField
                  label="Current Password"
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="New Password"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseChangePasswordDialog}>Cancel</Button>
                <Button onClick={handleChangePassword} color="primary">
                  Change Password
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={passwordExpired}
              onClose={() => setPasswordExpired(false)}
            >
              <DialogTitle>Password Expired</DialogTitle>
              <DialogContent>
                <Typography>
                  Your password has expired. Please contact Priority 6 to request a new password.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setPasswordExpired(false)}>OK</Button>
              </DialogActions>
            </Dialog>
            {showDisclaimer && !disclaimerAgreed && (
              <Disclaimer onAccept={handleDisclaimerAccept} onDecline={handleDisclaimerDecline} />
            )}
          </>
        ) : (
          <Typography variant="h6" align="center">
            Service Unavailable. Please try again later.
          </Typography>
        )
      ) : null}
    </Box>
  );
}

export default Login;
