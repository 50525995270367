import React from "react";
import { Box } from "@mui/material";
import HomePageSections from "./Pages/Home-Page-Sections/HomePageSections";
import ProgressNavbarLink from "./ProgressNavbarLink";
// import ProgressNavbarLink from "./LaserProgressNavbarLink";
import StaticNavbarLink from "./StaticNavbarLink";

function HomePageNavbar({ invertNavbar, isMenuOpen }) {
  let navbarSections = [];
  HomePageSections.forEach((section, index) => {
    if (section.inNavbar === true) {
      if (section.fullScreen === true) {
        navbarSections.push(
          <StaticNavbarLink
            key={section.title}
            sectionTitle={section.title}
            invertNavbar={invertNavbar}
          />,
        );
      } else {
        navbarSections.push(
          <ProgressNavbarLink
            key={section.title}
            sectionTitle={section.title}
            isFirstPage={index === 0}
            invertNavbar={invertNavbar}
            isMenuOpen={isMenuOpen}
          />,
        );
      }
    }
  });

  return (
    <Box
      align="center"
      component="div"
      sx={{
        display: "flex",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {navbarSections}
    </Box>
  );
}

export default HomePageNavbar;
