import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import PlayButton from "./PlayButton";
import ReactPlayer from "react-player";
import gsap from "gsap";
import { ScrollTrigger, ScrollSmoother } from "gsap/all";
import { scrollDownIndicatorRef } from "../../../App";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

const MemoizedReactPlayer = React.memo(ReactPlayer);

function VideoModal({ text, video }) {
  const containerRef = React.useRef();
  const smootherRef = React.useRef();
  const introContainerRef = React.useRef();
  const introTextRef = React.useRef();
  const textRef = React.useRef();
  const playButtonRef = React.useRef();
  const closeButtonRef = React.useRef();
  const videoBoxRef = React.useRef();
  const videoRef = React.useRef();
  const containerTL = React.useRef();
  const growModalTL = React.useRef();
  const openModalTL = React.useRef();
  const closeModalTL = React.useRef();
  const modalGrowDuration = 0.8;
  const theme = useTheme();

  const [playing, setPlaying] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const safeScrollDownIndicator = React.useCallback((method, ...args) => {
    if (scrollDownIndicatorRef.current && typeof scrollDownIndicatorRef.current[method] === 'function') {
      scrollDownIndicatorRef.current[method](...args);
    } else {
      console.warn(`scrollDownIndicatorRef.current.${method} is not available`);
    }
  }, []);

  React.useEffect(() => {
    smootherRef.current = ScrollSmoother.get();
  }, []);

  React.useEffect(() => {
    containerTL.current = gsap.timeline({
      onComplete: () => {
        gsap.set(containerRef.current, { autoAlpha: 1 });
        gsap.set(introContainerRef.current, { display: "none" });
      },
    });
    containerTL.current.from(
      introContainerRef.current,
      {
        width: () => gsap.utils.clamp(0, 900, window.innerWidth),
        yPercent: 50,
        duration: 1,
        ease: "power2.out",
      },
      0.2,
    );
    containerTL.current.from(
      introTextRef.current,
      {
        yPercent: 50,
        duration: 0.784,
        ease: "power2.out",
      },
      "<+=0.25",
    );

    return () => {
      containerTL.current?.kill();
      growModalTL.current?.kill();
      openModalTL.current?.kill();
      closeModalTL.current?.kill();
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  const openModal = React.useCallback(() => {
    safeScrollDownIndicator("hide");
    setIsModalOpen(true);
    // Pause ScrollSmoother when the modal opens
    if (smootherRef.current) {
      smootherRef.current.paused(true);
    }

    growModalTL.current = gsap.timeline();
    growModalTL.current.to(containerRef.current, {
      height: "100%",
      width: "100%",
      duration: modalGrowDuration,
      ease: "power2.inOut",
      backgroundColor: "#000",
    });

    openModalTL.current = gsap.timeline({
      onComplete: () => {
        safeScrollDownIndicator('instantHide');
      },
    });
    openModalTL.current.add("start", 0.1);
    openModalTL.current.add("hide content", 0);
    openModalTL.current.add("show close button", 0.85);
    openModalTL.current.add("show video", 0.7);

    const navbar = document.querySelector("header.MuiAppBar-root");
    openModalTL.current.to(
      navbar,
      { yPercent: -100, autoAlpha: 0, duration: 0.45, ease: "power1.in" },
      "start",
    );
    openModalTL.current.add(growModalTL.current, "start");
    openModalTL.current.set(
      videoBoxRef.current,
      {
        scale: 1,
        transformOrigin: "50% 50%",
      },
      "hide content",
    );
    openModalTL.current.to(
      [playButtonRef.current, textRef.current],
      {
        autoAlpha: 0,
        yPercent: -50,
        duration: 0.5,
        ease: "power1.in",
      },
      "hide content",
    );

    openModalTL.current.to(
      closeButtonRef.current,
      { autoAlpha: 1 },
      "show close button",
    );

    openModalTL.current.to(videoBoxRef.current, { autoAlpha: 1 }, "show video");
    setPlaying(true);
  }, [safeScrollDownIndicator, smootherRef]);

  const closeModal = React.useCallback(() => {
    setPlaying(false);
    setIsModalOpen(false);
    if (videoRef.current) {
      videoRef.current.playing = false;
      videoRef.current.seekTo(0); // Reset the video to the beginning
    }
    safeScrollDownIndicator('show');

    closeModalTL.current = gsap.timeline({
      onComplete: () => {
        if (!smootherRef.current) {
          console.warn("SmootherRef.current is undefined");
        } else {
          smootherRef.current.paused(false);
        }
        // Force a resize event to recalculate scroll positions
        window.dispatchEvent(new Event('resize'));
      },
    });

    closeModalTL.current.add("start", 0);
    closeModalTL.current.add("hide close button", 0);
    closeModalTL.current.add("hide video", 0);
    closeModalTL.current.add("show content", 0.9);

    const navbar = document.querySelector("header.MuiAppBar-root");
    closeModalTL.current.to(
      navbar,
      { yPercent: 0, autoAlpha: 1, duration: 0.5, ease: "power1.in" },
      "start",
    );

    closeModalTL.current.add(growModalTL.current.reverse(), "start");

    closeModalTL.current.to(
      closeButtonRef.current,
      { autoAlpha: 0, duration: 0.3 },
      "hide close button",
    );

    closeModalTL.current.to(
      videoBoxRef.current,
      {
        autoAlpha: 0,
        scale: 0.75,
        transformOrigin: "50% 50%",
        duration: 0.3,
        ease: "power1.out",
      },
      "hide video",
    );

    closeModalTL.current.set([playButtonRef.current, textRef.current], {
      yPercent: 0,
    });

    closeModalTL.current.to(
      [playButtonRef.current, textRef.current],
      {
        autoAlpha: 1,
        duration: 0.4,
        ease: "power1.inOut",
      },
      "show content",
    );
  }, [safeScrollDownIndicator, smootherRef]);

  const onVideoPlayStart = React.useCallback(() => {
    setPlaying(true);
  }, []);

  const onVideoPause = React.useCallback(() => {
    setPlaying(false);
  }, []);

  return (
    <>
      <Box
        ref={containerRef}
        sx={{
          border: `12px solid ${theme.palette.colors.darkBlue}`,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "63px 30px 30px",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          visibility: "hidden",
          [theme.breakpoints.up("xs")]: {
            width: "280px",
          },
          [theme.breakpoints.up("md")]: {
            width: "580px",
            padding: "63px 30px",
          },
          [theme.breakpoints.up("lg")]: {
            width: "680px",
          },
        }}
      >
        <Box ref={playButtonRef}>
          <PlayButton onClick={openModal} />
        </Box>
        <Typography
          ref={textRef}
          sx={{
            fontSize: "28px",
            fontFamily: "Normal Light",
            textAlign: "center",
            [theme.breakpoints.down("md")]: {
              width: "195px",
            },
            [theme.breakpoints.up("md")]: {
              fontSize: "38px",
              width: "520px",
            },
            [theme.breakpoints.up("lg")]: {
              fontSize: "38px",
              width: "620px",
            },
          }}
        >
          {text}
        </Typography>
      </Box>
      <Box
        ref={introContainerRef}
        sx={{
          border: `12px solid ${theme.palette.colors.darkBlue}`,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "63px 30px 30px",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          position: "absolute",
          [theme.breakpoints.up("xs")]: {
            width: "280px",
          },
          [theme.breakpoints.up("md")]: {
            width: "580px",
            padding: "63px 30px",
          },
          [theme.breakpoints.up("lg")]: {
            width: "680px",
          },
        }}
      >
        <Box>
          <PlayButton onClick={openModal} />
        </Box>
        <Typography
          ref={introTextRef}
          sx={{
            fontSize: "28px",
            fontFamily: "Normal Light",
            textAlign: "center",
            [theme.breakpoints.down("md")]: {
              width: "195px",
            },
            [theme.breakpoints.up("md")]: {
              fontSize: "38px",
              width: "520px",
            },
            [theme.breakpoints.up("lg")]: {
              fontSize: "38px",
              width: "620px",
            },
          }}
        >
          {text}
        </Typography>
      </Box>
      <Box
        ref={videoBoxRef}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          padding: "12px",
          boxSizing: "border-box",
          visibility: "hidden",
        }}
      >
        <IconButton
          ref={closeButtonRef}
          onClick={closeModal}
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{
            position: "absolute",
            top: "15px",
            right: "15px",
            visibility: "hidden",
            zIndex: "100",
          }}
          >
          <CloseIcon />
        </IconButton>
        {isModalOpen && (
          <MemoizedReactPlayer
          // <ReactPlayer
            ref={videoRef}
            key={video}
            url={video}
            width="100%"
            height="100%"
            loop={false}
            controls={true}
            playing={playing}
            volume={null}
            onStart={onVideoPlayStart}
            onPlay={onVideoPlayStart}
            onPause={onVideoPause}
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                  disablePictureInPicture: true,
                },
              },
            }}
          />
        )}
      </Box>
    </>
  );
}

export default VideoModal;