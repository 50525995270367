import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
  IconButton,
  Menu,
  MenuItem as MenuItemMUI,
  useMediaQuery,
  useTheme,
  InputAdornment,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { styled } from '@mui/system';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import RefreshIcon from '@mui/icons-material/Refresh';
import axios from "axios";

const serverUrl = process.env.REACT_APP_SERVER_URL ? process.env.REACT_APP_SERVER_URL : 'https://bbo1.rocks/server';

// Define the styled TableCell with responsive font size
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.6rem',  // Smaller text for mobile
    padding: '6px 8px',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: '0.7rem',  // Medium text for tablets
    padding: '8px 10px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '0.8rem',  // Larger text for desktops
    padding: '10px 12px',
  },
}));

// Create a new styled component for the header cells
const StyledHeaderCell = styled(StyledTableCell)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1e2a3a' : '#e0e0e0', // Lighter gray for light mode, darker blue-gray for dark mode
  color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
  fontWeight: 'bold',
  position: 'sticky',
  top: 0,
  zIndex: 1,
}));

function UserManagement() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [openDisclaimerInfo, setOpenDisclaimerInfo] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newUser, setNewUser] = useState({
    email: "",
    password: "",
    name: "",
    role: "user",
    passwordExpires: null,
    passwordExpiresAfter: null,
    mustChangePassword: false,
  });
  const [error, setError] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [actionUser, setActionUser] = useState(null);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const showSnackbar = (message, severity = 'info') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const fetchUsersWithRetry = useCallback((retryCount = 3) => {
    return new Promise((resolve, reject) => {
      const attemptFetch = (attemptsLeft) => {
        const token = localStorage.getItem("authToken");
        axios.get(`${serverUrl}/api/users`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(response => {
          console.log('Full API response:', response);
          resolve(response.data);
        })
        .catch(error => {
          console.error(`Attempt ${retryCount - attemptsLeft + 1} failed:`, error);
          if (attemptsLeft > 1) {
            setTimeout(() => attemptFetch(attemptsLeft - 1), 1000); // Wait 1 second before retrying
          } else {
            if (error.response && error.response.status === 401) {
              reject(new Error('Authentication failed. Please log in again.'));
            } else {
              reject(new Error('Failed to fetch users after multiple attempts'));
            }
          }
        });
      };

      attemptFetch(retryCount);
    });
  }, []);

  const fetchUsers = useCallback(() => {
    setLoading(true);
    fetchUsersWithRetry()
    .then(data => {
      setUsers(data);
    })
    .catch(error => {
      console.error("Failed to fetch users:", error);
      showSnackbar('Failed to fetch users', 'error');
    })
    .finally(() => {
      setLoading(false);
    });
  }, [fetchUsersWithRetry]);


  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleRefresh = () => {
    fetchUsers();
  };

  const handleOpenPopupForNewUser = () => {
    setOpenPopup(true);
    setIsEditing(false);
    setNewUser({
      email: "",
      password: "",
      name: "",
      role: "user",
      passwordExpires: null,
      passwordExpiresAfter: null,
      mustChangePassword: false,
    });
  };

  const handleOpenChangePassword = (user) => {
    setSelectedUser(user);
    setOpenChangePassword(true);
    setNewPassword("");
    setShowPassword(false);
    handleActionsClose();
  };

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
    setSelectedUser(null);
    setNewPassword("");
    setShowPassword(false);
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("authToken");
    axios.put(`${serverUrl}/api/users/${selectedUser.id}/change-password`,
      { newPassword },
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then(() => {
      showSnackbar('Password changed successfully', 'success');
      handleCloseChangePassword();
    })
    .catch(error => {
      console.error("Failed to change password:", error);
      showSnackbar('Failed to change password', 'error');
    });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleOpenPopupForEdit = (user) => {
    setOpenPopup(true);
    handleActionsClose();
    setIsEditing(true);
    setNewUser({
      id: user.id,
      email: user.email,
      name: user.name,
      role: user.role,
      passwordExpires: user.passwordExpires,
      passwordExpiresAfter: user.passwordExpiresAfter,
      mustChangePassword: user.mustChangePassword,
    });
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    setNewUser({
      id: null,
      email: "",
      password: "",
      name: "",
      role: "user",
      passwordExpires: null,
      passwordExpiresAfter: null,
      mustChangePassword: false,
    });
    setError("");
    setSelectedUser(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!newUser.email.includes('@')) {
      showSnackbar('Invalid email', 'error');
      return;
    }
    if (!isEditing && newUser.password && newUser.password.length < 8) {
      showSnackbar('Password must be at least 8 characters', 'error');
      return;
    }

    let requestData = {
      email: newUser.email,
      name: newUser.name,
      role: newUser.role,
      passwordExpires: newUser.passwordExpires,
      passwordExpiresAfter: newUser.passwordExpiresAfter,
      mustChangePassword: newUser.mustChangePassword,
    };

    // For adding a new user, password is required
    // For editing, the password is not included
    if (!isEditing) {
      requestData.password = newUser.password;
    }

    // Send the request to either add or update the user
    const token = localStorage.getItem("authToken");
    const url = isEditing ? `${serverUrl}/api/users/${newUser.id}` : `${serverUrl}/api/register`;
    const method = isEditing ? axios.put : axios.post;

    method(url, requestData, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(() => {
      showSnackbar(isEditing ? 'User updated successfully' : 'User created successfully', 'success');
      fetchUsers();
      handleClosePopup();
    }).catch(error => {
      console.error(isEditing ? "Failed to update user data:" : "Failed to save user data:", error);
      showSnackbar(isEditing ? 'Failed to update user data' : 'Failed to save user data', 'error');
    });
  };

  const handleDeleteUser = async (userId) => {
    const token = localStorage.getItem("authToken");
    axios.delete(`${serverUrl}/api/users/${userId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(() => {
      fetchUsers();
      showSnackbar('User deleted successfully', 'success');
    })
    .catch((error) => {
      console.error("Failed to delete user:", error);
      showSnackbar('Failed to delete user', 'error');
    })
    .finally(() => {
      handleActionsClose();
    });
  };

  const handleViewHistory = (user) => {
    console.log('Selected user for history view:', user);
    setSelectedUser(user);
    setOpenHistory(true);
    handleActionsClose();
  };

  const handleCloseHistory = () => {
    setOpenHistory(false);
    setSelectedUser(null);
    handleActionsClose();
  };

  const handleViewDisclaimerInfo = (user) => {
    setSelectedUser(user);
    setOpenDisclaimerInfo(true);
    handleActionsClose();
  };

  const handleCloseDisclaimerInfo = () => {
    setOpenDisclaimerInfo(false);
    setSelectedUser(null);
    handleActionsClose();
  };

  const handleActionsClick = (event, user) => {
    setActionUser(user);
    setAnchorEl(event.currentTarget);
  };

  const handleActionsClose = () => {
    setActionUser(null);
    setAnchorEl(null);
  };

  const handleResetLoginCount = (userId) => {
    const token = localStorage.getItem("authToken");
    axios.put(`${serverUrl}/api/users/${userId}/reset-login-count`, null, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(() => {
      showSnackbar('Login count reset successfully', 'success');
      fetchUsers();
    })
    .catch(error => {
      console.error("Failed to reset login count:", error);
      showSnackbar('Failed to reset login count', 'error');
    })
    .finally(() => {
      handleActionsClose();
    });
  };

  const handleClearHistory = (userId) => {
    const token = localStorage.getItem("authToken");
    axios.put(`${serverUrl}/api/users/${userId}/clear-history`, null, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(() => {
      fetchUsers();
      showSnackbar('User history cleared successfully', 'success');
    })
    .catch(error => {
      console.error("Failed to clear login history:", error);
      showSnackbar('Failed to clear user history', 'error');
    })
    .finally(() => {
      handleActionsClose();
    });
  };

  const handleResetDisclaimerAcceptance = (userId) => {
    const token = localStorage.getItem("authToken");
    axios.put(`${serverUrl}/api/users/${userId}/reset-disclaimer`, null, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(() => {
      showSnackbar('Disclaimer acceptance has been reset successfully', 'success');
      fetchUsers(); // Refresh the user list
    })
    .catch(error => {
      console.error("Failed to reset disclaimer acceptance:", error);
      showSnackbar('Failed to reset disclaimer acceptance', 'error');
    })
    .finally(() => {
      handleActionsClose();
    });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        Height: '100vh',
        overflow: 'hidden',
      }}
    >
      <Box sx={{
        flexShrink: 0, // Prevent the header from shrinking
        padding: 2,
        backgroundColor: (theme) => theme.palette.background.default,
        borderBottom: '1px solid',
        borderColor: (theme) => theme.palette.divider,
      }}>
        <Typography
          variant="h4"
          sx={{
            fontSize: {
              xs: "1.25rem", // Small screens
              sm: "2rem",   // Medium screens
              md: "2.5rem", // Large screens
            },
            textAlign: 'center',
            marginBottom: 1,
            flexShrink: 0,
            backgroundColor: (theme) => theme.palette.background.default,
            zIndex: 2,
          }}
        >
          User Management
        </Typography>
        <IconButton onClick={handleRefresh} color="primary">
          <RefreshIcon />
        </IconButton>
      </Box>
      {/* Render a table with user information */}
      <Box
        sx={{
          flex: 1,
          overflow: 'auto',
          marginBottom: '80px', // Adjust this value to control space above the button
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            borderRadius: 2,
            marginBottom: 2,
          }}
        >
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Name</StyledHeaderCell>
                <StyledHeaderCell>Email</StyledHeaderCell>
                <StyledHeaderCell>Role</StyledHeaderCell>
                <StyledHeaderCell>Password Expiration</StyledHeaderCell>
                <StyledHeaderCell>Login Count</StyledHeaderCell>
                <StyledHeaderCell>Change Password</StyledHeaderCell>
                <StyledHeaderCell>Disclaimer</StyledHeaderCell>
                <StyledHeaderCell>Actions</StyledHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.length > 0 ? (
                users.map((user) => (
                  <TableRow key={user.id}>
                    <StyledTableCell>{user.name}</StyledTableCell>
                    <StyledTableCell>{user.email}</StyledTableCell>
                    <StyledTableCell>{user.role}</StyledTableCell>
                    <StyledTableCell>
                      {user.passwordExpires
                        ? `Expires on ${new Date(user.passwordExpires).toLocaleString()}`
                        : user.passwordExpiresAfter
                        ? `Expires after ${user.passwordExpiresAfter} logins`
                        : "-"}
                    </StyledTableCell>
                    <StyledTableCell>{user.loginCount}</StyledTableCell>
                    <StyledTableCell>
                      {user.mustChangePassword ? 'Yes' : 'No'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {user.disclaimerAgreed ? 'Accepted' : '-'}
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        aria-label="more"
                        onClick={(event) => handleActionsClick(event, user)}
                        size="small" // Make the icon button smaller
                      >
                        <MoreVertIcon fontSize="small" />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleActionsClose}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        <MenuItemMUI onClick={() => handleOpenPopupForEdit(actionUser)}>
                          Edit
                        </MenuItemMUI>
                        <MenuItemMUI onClick={() => handleOpenChangePassword(actionUser)}>
                          Change Password
                        </MenuItemMUI>
                        <MenuItemMUI onClick={() => handleDeleteUser(actionUser.id)}>
                          Delete
                        </MenuItemMUI>
                        <MenuItemMUI onClick={() => handleViewHistory(actionUser)}>
                          View History
                        </MenuItemMUI>
                        <MenuItemMUI onClick={() => handleResetLoginCount(actionUser.id)}>
                          Reset Login Count
                        </MenuItemMUI>
                        <MenuItemMUI onClick={() => handleClearHistory(actionUser.id)}>
                          Clear History
                        </MenuItemMUI>
                        <MenuItemMUI onClick={() => handleViewDisclaimerInfo(actionUser)}>
                          View Disclaimer Information
                        </MenuItemMUI>
                        <MenuItemMUI onClick={() => handleResetDisclaimerAcceptance(actionUser.id)}>
                          Reset Disclaimer Acceptance
                        </MenuItemMUI>
                      </Menu>
                    </StyledTableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <StyledTableCell colSpan={8} align="center">No users found</StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box
        sx={{
          flexShrink: 0,
          padding: 2,
          display: 'flex',
          justifyContent: "center",
          backgroundColor: (theme) => theme.palette.background.default,
          borderTop: '1px solid',
          borderColor: (theme) => theme.palette.divider,
        }}
      >
        {/* Render a button to open the popup */}
        <Button
          onClick={handleOpenPopupForNewUser}
          variant="contained"
          sx={{
            fontSize: {
              xs: "0.6rem",
              sm: "0.75rem",
              md: "0.9rem",
            },
            padding: {
              xs: "4px 10px",
              sm: "6px 12px",
              md: "8px 14px",
            },
            border: '2px solid #152a7a',
            borderRadius: '8px',
            backgroundColor: 'rgba(23, 39, 102, 0.4)',
            color: '#ffffff',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
            transition: 'all 0.3s ease',
            '&:hover': {
              color: '#dbdbe0',
              backgroundColor: 'rgba(23, 39, 102, 0.7)',
            },
          }}
        >
          Add User
        </Button>
      </Box>
      {/* Add new Dialog for changing password */}
      <Dialog open={openChangePassword} onClose={handleCloseChangePassword}>
        <DialogTitle>Change Password for {selectedUser?.name}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleChangePassword}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="New Password"
              type={showPassword ? "text" : "password"}
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseChangePassword}>Cancel</Button>
          <Button onClick={handleChangePassword}>Change Password</Button>
        </DialogActions>
      </Dialog>
      {/* Render the popup */}
      <Dialog
        open={openPopup}
        onClose={handleClosePopup}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{isEditing ? "Edit User" : "Register User"}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={newUser.email}
              onChange={handleInputChange}
            />
            {!isEditing && ( // The field is only shown when registering a user, not when editing
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={newUser.password}
                onChange={handleInputChange}
                placeholder="Enter password"
              />
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              value={newUser.name}
              onChange={handleInputChange}
            />
            <FormControl fullWidth margin="normal" required>
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                labelId="role-label"
                id="role"
                name="role"
                value={newUser.role}
                label="Role"
                onChange={handleInputChange}
              >
                <MenuItem value="user">User</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="normal"
              fullWidth
              id="passwordExpires"
              label="Password Expiration Date"
              name="passwordExpires"
              type="date"
              value={newUser.passwordExpires || ""}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              margin="normal"
              fullWidth
              id="passwordExpiresAfter"
              label="Password Expiration After (number of logins)"
              name="passwordExpiresAfter"
              type="number"
              value={newUser.passwordExpiresAfter || ""}
              onChange={handleInputChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={newUser.mustChangePassword}
                  onChange={(e) =>
                    setNewUser((prevUser) => ({ ...prevUser, mustChangePassword: e.target.checked, }))
                  }
                  name="mustChangePassword"
                />
              }
              label="User must change password on next login"
            />
            {error && <Typography color="error">{error}</Typography>}
            <DialogActions>
              <Button onClick={handleClosePopup}>Cancel</Button>
              <Button type="submit">
                {isEditing ? "Update" : "Register"}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      {/* History Dialog */}
      <Dialog open={openHistory} onClose={handleCloseHistory}
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            width: '800px',
            maxWidth: 'none',
          },
        }}
      >
        <DialogTitle>History for {selectedUser?.name}</DialogTitle>
        <DialogContent sx={{ maxHeight: '400px', overflow: 'auto' }}>
          {console.log('selectedUser in dialog:', selectedUser)}
          {selectedUser ? (
            !selectedUser.history || selectedUser.history.length === 0 ? (
            <Typography>No history available.</Typography>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2} sx={{ fontWeight: 'bold', fontSize: '0.7rem', borderBottom: '1px solid #ccc', paddingBottom: '8px' }}>
                  <Grid item xs={2} sx={{ paddingLeft: '16px' }}>
                    Type
                  </Grid>
                  <Grid item xs={3}>
                    Time
                  </Grid>
                  <Grid item xs={2}>
                    IP Address
                  </Grid>
                  <Grid item xs={3}>
                    Details
                  </Grid>
                  <Grid item xs={2} sx={{ paddingRight: '16px' }}>
                  Authorized By
                  </Grid>
                </Grid>
              </Grid>
              {selectedUser.history.map((evt, index) => (
                <Grid container spacing={2} key={index} sx={{ fontSize: '0.7rem', borderBottom: '1px solid #f0f0f0', paddingTop: '8px', paddingBottom: '8px' }}>
                  <Grid item xs={2} sx={{ paddingLeft: '16px' }}>
                    {evt.type}
                  </Grid>
                  <Grid item xs={3}>
                    {new Date(evt.timestamp).toLocaleString()}
                  </Grid>
                  <Grid item xs={2}>
                    {evt.ipAddress}
                  </Grid>
                  <Grid item xs={3}>
                    {evt.details}
                  </Grid>
                  <Grid item xs={2} sx={{ paddingRight: '16px' }}>
                    {evt.authorizedBy}
                  </Grid>
                </Grid>
              ))}
            </Grid>
            )
          ) : (
            <Typography>Loading...</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHistory}>Close</Button>
        </DialogActions>
      </Dialog>
      {/* Disclaimer dialog */}
      <Dialog open={openDisclaimerInfo} onClose={handleCloseDisclaimerInfo}
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <DialogTitle>Disclaimer Agreement Information for {selectedUser?.name}</DialogTitle>
        <DialogContent sx={{ maxHeight: '400px', overflow: 'auto' }}>
          {selectedUser ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid
                  container spacing={2}
                  sx={{
                    fontWeight: 'bold',
                    fontSize: "0.7rem",
                    borderBottom: '1px solid #ccc',
                    paddingBottom: '8px'
                  }}
                >
                  <Grid item xs={2} sx={{ paddingLeft: '16px' }}>
                    Agreed
                  </Grid>
                  <Grid item xs={5}>
                    Timestamp
                  </Grid>
                  <Grid item xs={5} sx={{ paddingRight: '16px' }}>
                    IP Address
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ fontSize: '0.7rem', borderBottom: '1px solid #f0f0f0', paddingTop: '8px', paddingBottom: '8px'  }}>
                <Grid item xs={2} sx={{ paddingLeft: '16px' }}>
                  {selectedUser.disclaimerAgreed ? 'Yes' : 'No'}
                </Grid>
                <Grid item xs={5}>
                  {selectedUser.disclaimerAgreementTimestamp
                    ? new Date(selectedUser.disclaimerAgreementTimestamp).toLocaleString()
                    : 'N/A'}
                </Grid>
                <Grid item xs={5} sx={{ paddingRight: '16px' }}>
                  {selectedUser.disclaimerAgreementIP || 'N/A'}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Typography>Loading...</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDisclaimerInfo}>Close</Button>
        </DialogActions>
      </Dialog>
      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{
          bottom: { xs: 90, sm: 0 }  // Adjust bottom margin for mobile devices
        }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
          elevation={6}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default UserManagement;