import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import StarWarsText from "../../StarWarsText";
import BackgroundVideo from './BackgroundVideo';

import Logo from "../../../images/Priority_6_Logo.webp";
import video from "../../../videos/Sequence01.webm";
import poster from "../../../images/Sequence01-first.webp";

gsap.registerPlugin(ScrollTrigger);

function Start() {
  const containerRef = React.useRef();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      {/* Pass the video and poster as props */}
      <BackgroundVideo videoSrc={video} posterSrc={poster} />
      <Box
        sx={{
          backgroundColor: "transparent",
          position: "relative",
          visibility: "visible",
        }}
      >
        <Box
          ref={containerRef}
          sx={{
            position: "relative",
            zIndex: 100,
            "& a": {
              color: "#fff",
            },
          }}
        >
          {/* text */}
          <StarWarsText isSmallScreen={isSmallScreen} specialLastLine={true}>
            <span className="text-32">The most monumental transformation in the history of humanity is upon us.</span><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <span className="text-32">In the next six years our global market cap will surge from</span><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <span className="text-32">$117 trillion to $360 trillion.</span><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <span className="text-32">The catalyst driving this economic revolution is</span><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <span className="text-32">Artificial Intelligence.</span><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <span className="text-32">Artificial Intelligence is tethered to energy. One will not exist without the other.</span><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <span className="text-32">The generation of energy is intrinsically dependent on earth's natural resources.</span><br/><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <span className="text-32">We have invented the single most powerful financial instrument on the planet.</span><br/><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <span className="text-32">Fusing financial returns to the materials that everything is made from.</span><br/><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <span className="text-32">Capturing an endless stream of value.</span><br/><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <span className="text-32">Those who seize this moment will create new economies</span><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <span className="text-32">becoming the titans of our era.</span><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <span className="text-32">Capturing fire was our greatest accomplishment.</span><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <span className="text-32">Until now.</span><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <span className="text-32">Welcome to</span><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <span className="image-container">
              <img src={Logo} alt="Priority 6" style={{ maxWidth: "100%", height: "auto" }} />
            </span>
            {/* <span className="text-120 rephen">Priority</span><span className="text-124 red dioropen">6</span> */}
          </StarWarsText>
        </Box>
      </Box>
    </Box>
  );
}

export default Start;
