import React from 'react';
import Start from "./Start";
import Solution from "./Solution";
import Catalyst from "./Catalyst";
import Problem from "./Problem";
import BBO1 from "./BBO1";
import TheEnd from "./TheEnd";

const HomePageSections = [
  {
    component: Start,
    path: "welcome",
    title: "welcome",
    fullScreen: false,
    inNavbar: true,
    containerRef: React.createRef(),
    textContainerRef: React.createRef(),
    invertNavbar: false,
  },
  {
    component: Catalyst,
    path: "BBO1",
    title: "BBO1",
    fullScreen: true,
    inNavbar: true,
    containerRef: React.createRef(),
    textContainerRef: React.createRef(),
    invertNavbar: false,
  },
  {
    component: Problem,
    path: "Problem",
    title: "Problem",
    fullScreen: false,
    inNavbar: true,
    containerRef: React.createRef(),
    textContainerRef: React.createRef(),
    invertNavbar: false,
  },
  {
    component: Solution,
    path: "Solution",
    title: "Solution",
    fullScreen: false,
    inNavbar: true,
    containerRef: React.createRef(),
    textContainerRef: React.createRef(),
    invertNavbar: false,
  },

  {
    component: BBO1,
    path: "Opportunity",
    title: "Opportunity",
    fullScreen: true,
    inNavbar: true,
    containerRef: React.createRef(),
    textContainerRef: React.createRef(),
    invertNavbar: false,
  },
  {
    component: TheEnd,
    path: "company",
    title: "company",
    fullScreen: true,
    inNavbar: true,
    containerRef: React.createRef(),
    textContainerRef: React.createRef(),
    invertNavbar: false,
    coloredNavbar: "#08102e",
  },
];

export default HomePageSections;
