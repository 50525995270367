import React, {} from "react";
import { Box } from "@mui/material";
import HomePageFullScreenSection from "./HomePageFullScreenSection";
import VideoModal from "./VideoModal";
import BackgroundVideo from './BackgroundVideo';

import video from "../../../videos/Starry-Sky.webm";
import poster from "../../../images/starry-sky-first-frame.webp";
import playVideo from "../../../videos/OpportunityVideo.webm";

function BBO1() {
  return (
    <HomePageFullScreenSection backgroundColor="transparent">
      {/* Pass the video and poster as props */}
      <BackgroundVideo videoSrc={video} posterSrc={poster} />
      <Box
        sx={{
          zIndex: 10,
          height: "100%",
          width: "100%",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          userSelect: 'none',
        }}
      >
        <VideoModal text="Welcome to the Revolution" video={playVideo} />
      </Box>
    </HomePageFullScreenSection>
  );
}

export default BBO1;
