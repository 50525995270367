import React, { } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import StarWarsText from "../../StarWarsText";
import BackgroundVideo from './BackgroundVideo';

import video from "../../../videos/mountain-tops.webm";
import poster from "../../../images/mountain-tops.webp";

gsap.registerPlugin(ScrollTrigger);

function Solution() {
  const containerRef = React.useRef();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      {/* Pass the video and poster as props */}
      <BackgroundVideo videoSrc={video} posterSrc={poster} />
      <Box
        ref={containerRef}
        sx={{
          backgroundColor: "transparent",
          position: "relative",
          visibility: "visible",
        }}
      >
        <Box
          sx={{
            position: "relative",
            zIndex: 100,
            "& a": {
              color: "#fff",
            },
          }}
        >
          {/* text */}
          <StarWarsText isSmallScreen={isSmallScreen} specialLastLine={true}>
            <span className="text-32">The internet connected the world and democratized access to information.</span><br/>
            <br />
            <br />
            <br />
            <br />
            <span className="text-32">Tokenization unlocks our world's value and democratizes access to wealth.</span><br/>
            <br />
            <br />
            <br />
            <br />
            <span className="text-32">Redefining investments in natural resources,</span><br/>
            <br />
            <br />
            <br />
            <br />
            <span className="text-32">making everything on the planet digital, liquid and tradeable.</span><br/>
            <br />
            <br />
            <br />
            <br />
            <span className="text-32">We are harnessing the power of converging technologies and inventing the future with</span><br/>
            <br />
            <br />
            <br />
            <br />
            <span className="text-32">Artificial Intelligence, Cybersecurity, Tokenization.</span><br/>
            <br />
            <br />
            <br />
            <br />
            <span className="text-32">We use AI to discover and define, with certainty, the value beneath our feet, increasing our confidence in subsurface resources, driving efficiency and speed to market.</span><br/>
            <br />
            <br />
            <br />
            <br />
            <br />
            <span className="text-32">We tokenize mineral assets to unlock trillions of dollars of untapped value,</span><br/>
            <br />
            <br />
            <br />
            <br />
            <span className="text-32">enabling continuous revenue generation across the entire supply chain,</span><br/>
            <br />
            <br />
            <br />
            <br />
            <span className="text-32">transforming the way we value and trade natural resources, as new lucrative assets.</span><br/>
            <br />
            <br />
            <br />
            <br />
            <span className="text-32">We are breaking down barriers to entry, empowering every single human</span><br/>
            <br />
            <br />
            <br />
            <br />
            <span className="text-32">to invest in the earth through a more efficient, transparent and accessible market.</span><br/>
            <br />
            <br />
            <br />
            <br />
            <span className="text-32">Join us as we redefine the future of resource discovery and monetization.</span><br/>
            <br />
          </StarWarsText>
        </Box>
      </Box>
    </Box>
  );
}

export default Solution;
