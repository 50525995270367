import React from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import gsap from "gsap";
import { ScrollTrigger, ScrollSmoother } from "gsap/all";
import NavbarLinkWrapper from "./NavbarLinkWrapper";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

function StaticNavbarLink({ sectionTitle, invertNavbar }) {
  const theme = useTheme();
  const containerRef = React.useRef();
  const activeSectionIndicatorRef = React.useRef();
  const location = useLocation();
  const isCurrentSection = React.useRef(false);
  const linkTextRef = React.useRef(null);
  const [linkWidth, setLinkWidth] = React.useState(0);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Sync the width of the progressIndicator with the LinkText
  React.useEffect(() => {
    if (linkTextRef.current && activeSectionIndicatorRef.current) {
      const textWidth = linkTextRef.current.offsetWidth;
      setLinkWidth(textWidth);

      // Set activeSectionIndicatorRef width directly:
      activeSectionIndicatorRef.current.style.width = `${textWidth}px`;
    }
  }, [linkWidth, sectionTitle, isSmallScreen, isMediumScreen]);

  // set Navbar functionality based on route location
  React.useEffect(() => {
    // is this the current section?
    if (location.pathname.includes(sectionTitle)) {
      isCurrentSection.current = true;
      gsap.to(activeSectionIndicatorRef.current, {
        autoAlpha: 1,
        duration: 0.2,
        y: 0,
      });
    } else {
      // not current section
      isCurrentSection.current = false;
      gsap.set(activeSectionIndicatorRef.current, {
        autoAlpha: 0,
      });
      // setup underline functionality for not the current section
      containerRef.current.addEventListener("mouseenter", handleMouseEnter);
      containerRef.current.addEventListener("mouseleave", handleMouseLeave);
    }

    // eslint-disable-next-line
  }, [location]);

  function handleMouseEnter() {
    if (!isCurrentSection.current) {
      gsap.killTweensOf(activeSectionIndicatorRef.current);
      gsap.fromTo(
        activeSectionIndicatorRef.current,
        { autoAlpha: 0, y: 5 },
        {
          autoAlpha: 1,
          y: 0,
          duration: 0.3,
          ease: "power1.inOut",
        },
      );
    }
  }

  function handleMouseLeave() {
    if (!isCurrentSection.current) {
      gsap.killTweensOf(activeSectionIndicatorRef.current);
      gsap.to(activeSectionIndicatorRef.current, {
        autoAlpha: 0,
        y: 5,
        duration: 0.3,
        ease: "power1.inOut",
      });
    }
  }

  const LinkText = styled(Typography)(({ theme }) => ({
    textTransform: "uppercase",
    fontSize: isSmallScreen ? "6px" : isMediumScreen ? "10px" : "15px",
  }));

  return (
    <NavbarLinkWrapper sectionTitle={sectionTitle}>
      <Box
        ref={containerRef}
        sx={{
          padding: isSmallScreen ? "0 6px" : isMediumScreen ? "0 8px" :"0 14px",
          position: "relative",
          cursor: "pointer",
          minWidth: isSmallScreen ? "40px" : "auto",
        }}
      >
        <LinkText ref={linkTextRef}>
          {sectionTitle ? sectionTitle : "Priority 6"}
        </LinkText>
        <Box
          ref={activeSectionIndicatorRef}
          sx={{
            height: isSmallScreen ? "3px" : isMediumScreen ? "4px" : "5px",
            width: linkWidth === 0 ? "20px" : `${linkWidth}px`,
            visibility: linkWidth === 0 ? 'hidden' : 'visible',
            backgroundColor: 'darkblue',
            position: "absolute",
            bottom: isSmallScreen ? "-2px" : "-4px",
          }}
        ></Box>
      </Box>
    </NavbarLinkWrapper>
  );
}

export default StaticNavbarLink;
