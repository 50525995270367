import React from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import gsap from "gsap";
import { ScrollTrigger, ScrollSmoother } from "gsap/all";
import NavbarLinkWrapper from "./NavbarLinkWrapper";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

function ProgressNavbarLink({ sectionTitle, isFirstPage, invertNavbar, isMenuOpen }) {
  const theme = useTheme();
  const containerRef = React.useRef();
  const progressIndicatorRef = React.useRef();
  const progressIndicatorTrackRef = React.useRef();
  const pageScrollerRef = React.useRef(null);
  const linkTextRef = React.useRef(null);
  const [linkWidth, setLinkWidth] = React.useState(0);
  const [isCurrentSection, setIsCurrentSection] = React.useState(false);
  const location = useLocation();

  // Breakpoints for responsiveness
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  // special case for first section, which is the index route for /home
  function isFirstHomePageSection() {
    if (!location || !location.pathname) {
      throw new Error('Invalid location object');
    }
    const isHomePage = location.pathname === "/home/";
    const isIndexRoute = location.pathname === "/home";
    return isFirstPage && (isHomePage || isIndexRoute);
  }

  // Sync the width of the progressIndicator with the LinkText
  React.useEffect(() => {
    if (linkTextRef.current && progressIndicatorRef.current) {
      const textWidth = linkTextRef.current.offsetWidth;
      setLinkWidth(textWidth);

      // Set progressIndicator width directly:
      progressIndicatorRef.current.style.width = `${textWidth}px`;
    }
  }, [linkWidth, sectionTitle, isSmallScreen, isMediumScreen]);

  React.useEffect(() => {
    const currentContainerRef = containerRef.current;
    const currentIsSection = location.pathname.includes(sectionTitle) || isFirstHomePageSection();
    setIsCurrentSection(currentIsSection);
    if (isCurrentSection) {
      // use smoother content for scrollTrigger to give page scroll position
      const smoother = ScrollSmoother.get();
      const content = smoother.content();

      gsap.set(progressIndicatorRef.current, {
        scaleX: 0,
        autoAlpha: 1,
        transformOrigin: "0% 50%",
      });

      pageScrollerRef.current = ScrollTrigger.create({
        trigger: content,
        start: "top top",
        end: "bottom bottom",
        onUpdate: (self) => {
          // set link underline from scrollTrigger progress
          gsap.set(progressIndicatorRef.current, {
            scaleX: self.progress,
            transformOrigin: "0% 50%",
          });
        },
      });

      // Refresh the ScrollTrigger after a short delay to ensure the component has mounted
      gsap.delayedCall(0.1, () => {
        ScrollTrigger.refresh();
      });
    } else {
      // reset trigger
      if (pageScrollerRef.current !== null) {
        pageScrollerRef.current.kill();
        pageScrollerRef.current = null;
      }

      // setup underline functionality for not the current section
      gsap.set([progressIndicatorRef.current], {
        scaleX: 1,
        autoAlpha: 0,
      });

      currentContainerRef?.addEventListener("mouseenter", handleMouseEnter);
      currentContainerRef?.addEventListener("mouseleave", handleMouseLeave);
    }

    // Clean up the event listeners when the component unmounts
    return () => {
      currentContainerRef?.removeEventListener("mouseenter", handleMouseEnter);
      currentContainerRef?.removeEventListener("mouseleave", handleMouseLeave);
      if (pageScrollerRef.current !== null) {
        pageScrollerRef.current.kill();
        pageScrollerRef.current = null;
      }
    };

    // eslint-disable-next-line
  }, [location, isCurrentSection, isMenuOpen]);

  function handleMouseEnter() {
    if (!isCurrentSection) {
      gsap.killTweensOf(progressIndicatorRef.current);
      gsap.fromTo(
        progressIndicatorRef.current,
        { autoAlpha: 0, y: 5 },
        {
          autoAlpha: 1,
          y: 0,
          duration: 0.3,
          ease: "power1.inOut",
        },
      );
    }
  }

  function handleMouseLeave() {
    if (!isCurrentSection) {
      gsap.killTweensOf(progressIndicatorRef.current);
      gsap.to(progressIndicatorRef.current, {
        autoAlpha: 0,
        y: 5,
        duration: 0.3,
        ease: "power1.inOut",
      });
    }
  }

  const LinkText = styled(Typography)(({ theme }) => ({
    textTransform: "uppercase",
    fontSize: isSmallScreen ? "6px" : isMediumScreen ? "10px" : "16px",
    whiteSpace: "nowrap",
  }));

  const ProgressIndicator = styled(Box)(({ theme }) => ({
    height: isSmallScreen ? "3px" : isMediumScreen ? "4px" : "5px",
    width: linkWidth === 0 ? "20px" : `${linkWidth}px`,
    visibility: linkWidth === 0 ? 'hidden' : 'visible',
    backgroundColor: invertNavbar ? "#0f1940" : "#b3b3b3",
    position: "absolute",
    bottom: isSmallScreen ? "-2px" : "-4px",
  }));

  return (
    <NavbarLinkWrapper sectionTitle={sectionTitle}>
      <Box
        ref={containerRef}
        sx={{
          padding: isSmallScreen ? "0 6px" : isMediumScreen ? "0 8px" :"0 14px",
          position: "relative",
          cursor: "pointer",
          minWidth: isSmallScreen ? "40px" : "auto",
        }}
      >
        <LinkText ref={linkTextRef}>
          {sectionTitle ? sectionTitle : "Priority 6"}
        </LinkText>
        <ProgressIndicator
          ref={progressIndicatorTrackRef}
          sx={{
            visibility: isCurrentSection && !isMenuOpen ? "visible" : "hidden",
          }}
        />
        <ProgressIndicator
          ref={progressIndicatorRef}
          sx={{
            backgroundColor: invertNavbar ? "#007FFF" : 'darkblue',
            transform: "scaleX(0)"
          }}
        />
      </Box>
    </NavbarLinkWrapper>
  );
}

export default ProgressNavbarLink;
