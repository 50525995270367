import React from "react";
import {
  Box,
  Typography,
  useTheme
} from "@mui/material";
import styled from '@emotion/styled';
import useMediaQuery from "@mui/material/useMediaQuery";
import gsap from "gsap";

const ScrollDownIndicator = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const containerRef = React.useRef();
  const innerWrapperRef = React.useRef();
  const showTL = React.useRef();
  const hideTL = React.useRef();
  const chevronTL = React.useRef();
  const svgRef = React.useRef();
  const colorSchemeTL = React.useRef();
  const colors = {
    white: "#fff",
    black: "#000",
    backgroundColor: "#fff",
  };

  // Breakpoints for responsiveness
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const OuterBox = styled(Box)({
    position: "fixed",
    bottom: "20px", // Adjust as needed to fit into the copyright notice cutout
    width: "100%",
    zIndex: 90,
    textAlign: "center",
    visibility: "hidden",
  });

  const InnerBox = styled(Box)({
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: 'transparent',
    flexDirection: "row",
    padding: isSmallScreen ? "4px 12px" : isMediumScreen ? "5px 15px" : "6px 18px",
    borderRadius: "20px",
    width: '110px', // Match the width of the cutout
    height: '30px', // Match the height of the cutout
  });

  const StyledTypography = styled(Typography)({
    color: "#fff",
    textTransform: "uppercase",
    fontSize: isSmallScreen ? "8px" : isMediumScreen ? "9px" : "10px",
    fontFamily: "Normal Light",
    letterSpacing: "3px",
    userSelect: "none",
    cursor: "default",
    margin: "0 4px",
  });

  const StyledSVG = styled('svg')({
    fill: "#fff",
    margin: "0 9px 0 5px",
    height: isSmallScreen ? "16px" : isMediumScreen ? "20px" : "24px",
    width: isSmallScreen ? "12px" : isMediumScreen ? "16px" : "20px",
    overflow: "visible",
    "& path": {
      stroke: "#fff",
    },
  });

  function show() {
    gsap.killTweensOf(containerRef.current);
    showTL.current = gsap.timeline();
    showTL.current.to(containerRef.current, {
      autoAlpha: 1,
      duration: 0.66,
      ease: "power1.inOut",
      onComplete: animateChevrons,
    });
  }

  function hide() {
    if (gsap.getProperty(containerRef.current, "autoAlpha") > 0) {
      gsap.killTweensOf(containerRef.current);
      hideTL.current = gsap.timeline({
        onStart: stopChevronAnimation,
        onComplete: hideBackgroundColor,
      });
      hideTL.current.to(containerRef.current, { autoAlpha: 0, duration: 0.2 });
    }
  }

  function instantHide() {
    gsap.killTweensOf(containerRef.current);
    hideTL.current = gsap.timeline();
    hideTL.current.set(containerRef.current, { autoAlpha: 0 });
    hideBackgroundColor();
  }

  function colorSchemeWhite() {
    changeColorScheme(colors.white);
  }

  function colorSchemeBlack() {
    changeColorScheme(colors.black);
  }

  function changeColorScheme(color) {
    gsap.killTweensOf(containerRef.current);
    colorSchemeTL.current = gsap.timeline();
    colorSchemeTL.current.set(
      containerRef.current.querySelectorAll(".scroll-down-label-text"),
      { color: color },
    );
    colorSchemeTL.current.set(containerRef.current.querySelector("svg"), {
      fill: color,
    });
    if (color === colors.black) {
      colorSchemeTL.current.set(innerWrapperRef.current, {
        backgroundColor: colors.backgroundColor,
      });
    } else {
      hideBackgroundColor();
    }
  }

  function hideBackgroundColor() {
    gsap.set(innerWrapperRef.current, {
      backgroundColor: "transparent",
    });
  }

  function animateChevrons() {
    const chevrons = svgRef.current.querySelectorAll("path");
    gsap.killTweensOf(chevrons);
    chevronTL.current = gsap.timeline({
      y: 0,
      delay: 5,
      repeat: -1,
      repeatDelay: 3,
      onRepeat: () => {
        gsap.set(chevrons, { y: 0 });
      },
    });
    for (let i = 0; i < 3; i++) {
      chevronTL.current.to(
        chevrons,
        {
          y: "+=100",
          opacity: 0.8,
          ease: "power2.inOut",
          duration: 0.66,
          // stagger: 0.1,
          yoyo: true,
          repeat: 1,
        },
      )
    }
  }

  function stopChevronAnimation() {
    if (typeof chevronTL.current !== "undefined") {
      chevronTL.current.kill();
    }
  }

  function getSVGRef() {
    return svgRef;
  }

  // useImperativeHandle to export methods
  // allows the following methods to be called with scrollDownIndicatorRef exported in App.js
  React.useImperativeHandle(ref, () => ({
    show,
    hide,
    instantHide,
    colorSchemeWhite,
    colorSchemeBlack,
    getSVGRef,
  }));

  return (
    <OuterBox ref={containerRef}>
      <InnerBox ref={innerWrapperRef}>
        {/* sx={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "transparent",
          flexDirection: "row",
          padding: isSmallScreen ? "4px" : isMediumScreen ? "5px" : "6px",
          borderRadius: "50px",
          "& .scroll-down-label-text": {
            textTransform: "uppercase",
            fontSize: isSmallScreen ? "8px" : isMediumScreen ? "9px" : "10px",
            fontFamily: "Normal Light",
            letterSpacing: "3px",
            userSelect: "none",  // Prevent text selection
            cursor: "default",   // Prevent cursor from changing
          },
          "& svg": {
            fill: "#fff",
            margin: "0 9px 0 5px",
            height: isSmallScreen ? "16px" : isMediumScreen ? "20px" : "24px",
            width: isSmallScreen ? "12px" : isMediumScreen ? "16px" : "20px",
            overflow: "visible",
            "& path": {
              stroke: "#fff",
            },
          },
        }}
      > */}
        <StyledTypography>Scroll</StyledTypography>
        <StyledSVG
          ref={svgRef}
          viewBox="0 0 448 512"
          style={{ pointerEvents: "none" }}  // Prevent any pointer events on SVG
        >
          <path
            id="top-chevron"
            d="M406.6,118.6l-160,160c-12.5,12.5-32.8,12.5-45.3,0l-160-160c-12.5-12.5-12.5-32.8,0-45.3
	s32.8-12.5,45.3,0L224,210.7L361.4,73.4c12.5-12.5,32.8-12.5,45.3,0S419.2,106.2,406.6,118.6L406.6,118.6z"
          />
          <path
            id="bottom-chevron"
            d="M246.6,470.6c-12.5,12.5-32.8,12.5-45.3,0l-160-160c-12.5-12.5-12.5-32.8,0-45.3s32.8-12.5,45.3,0
	L224,402.7l137.4-137.3c12.5-12.5,32.8-12.5,45.3,0s12.5,32.8,0,45.3L246.6,470.6L246.6,470.6z"
          />
        </StyledSVG>
        <StyledTypography>Down</StyledTypography>
      </InnerBox>
    </OuterBox>
  );
});

export default ScrollDownIndicator;
